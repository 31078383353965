import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import emojiIcon from '../theme/icons/emoji.svg';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import EmojiListView from './ui/emojilistview';
import ContextualBalloon from '@ckeditor/ckeditor5-ui/src/panel/balloon/contextualballoon';
import ClickObserver from '@ckeditor/ckeditor5-engine/src/view/observer/clickobserver';
import clickOutsideHandler from '@ckeditor/ckeditor5-ui/src/bindings/clickoutsidehandler';

export default class Emoji extends Plugin {
	/**
	 * @inheritDoc
	 */
	static get requires() {
		return [ ContextualBalloon ];
	}

	/**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'Emoji';
	}

	init() {
		const editor = this.editor;

		editor.editing.view.addObserver( ClickObserver );

		editor.config.define( 'emoji_full', [
			{
				'id': '1f604',
				'name': 'smile',
				'text': '😄'
			},
			{
				'id': '1f603',
				'name': 'smiley',
				'text': '😃'
			},
			{
				'id': '1f600',
				'name': 'grinning',
				'text': '😀'
			},
			{
				'id': '1f60a',
				'name': 'blush',
				'text': '😊'
			},

			/**
			 {
                'id': '263a',
                'name': 'relaxed',
                'text': '☺'
            },*/
			{
				'id': '1f609',
				'name': 'wink',
				'text': '😉'
			},
			{
				'id': '1f60d',
				'name': 'heart_eyes',
				'text': '😍'
			},
			{
				'id': '1f618',
				'name': 'kissing_heart',
				'text': '😘'
			},
			{
				'id': '1f61a',
				'name': 'kissing_closed_eyes',
				'text': '😚'
			},
			{
				'id': '1f617',
				'name': 'kissing',
				'text': '😗'
			},
			{
				'id': '1f619',
				'name': 'kissing_smiling_eyes',
				'text': '😙'
			},
			{
				'id': '1f61c',
				'name': 'stuck_out_tongue_winking_eye',
				'text': '😜'
			},
			{
				'id': '1f61d',
				'name': 'stuck_out_tongue_closed_eyes',
				'text': '😝'
			},
			{
				'id': '1f61b',
				'name': 'stuck_out_tongue',
				'text': '😛'
			},
			{
				'id': '1f633',
				'name': 'flushed',
				'text': '😳'
			},
			{
				'id': '1f601',
				'name': 'grin',
				'text': '😁'
			},
			{
				'id': '1f614',
				'name': 'pensive',
				'text': '😔'
			},
			{
				'id': '1f60c',
				'name': 'relieved',
				'text': '😌'
			},
			{
				'id': '1f612',
				'name': 'unamused',
				'text': '😒'
			},
			{
				'id': '1f61e',
				'name': 'disappointed',
				'text': '😞'
			},
			{
				'id': '1f623',
				'name': 'persevere',
				'text': '😣'
			},
			{
				'id': '1f622',
				'name': 'cry',
				'text': '😢'
			},
			{
				'id': '1f602',
				'name': 'joy',
				'text': '😂'
			},
			{
				'id': '1f62d',
				'name': 'sob',
				'text': '😭'
			},
			{
				'id': '1f62a',
				'name': 'sleepy',
				'text': '😪'
			},
			{
				'id': '1f625',
				'name': 'disappointed_relieved',
				'text': '😥'
			},
			{
				'id': '1f630',
				'name': 'cold_sweat',
				'text': '😰'
			},
			{
				'id': '1f605',
				'name': 'sweat_smile',
				'text': '😅'
			},
			{
				'id': '1f613',
				'name': 'sweat',
				'text': '😓'
			},
			{
				'id': '1f629',
				'name': 'weary',
				'text': '😩'
			},
			{
				'id': '1f62b',
				'name': 'tired_face',
				'text': '😫'
			},
			{
				'id': '1f628',
				'name': 'fearful',
				'text': '😨'
			},
			{
				'id': '1f631',
				'name': 'scream',
				'text': '😱'
			},
			{
				'id': '1f620',
				'name': 'angry',
				'text': '😠'
			},
			{
				'id': '1f621',
				'name': 'rage',
				'text': '😡'
			},
			{
				'id': '1f624',
				'name': 'triumph',
				'text': '😤'
			},
			{
				'id': '1f616',
				'name': 'confounded',
				'text': '😖'
			},
			{
				'id': '1f606',
				'name': 'satisfied',
				'text': '😆'
			},
			{
				'id': '1f60b',
				'name': 'yum',
				'text': '😋'
			},
			{
				'id': '1f637',
				'name': 'mask',
				'text': '😷'
			},
			{
				'id': '1f60e',
				'name': 'sunglasses',
				'text': '😎'
			},
			{
				'id': '1f634',
				'name': 'sleeping',
				'text': '😴'
			},
			{
				'id': '1f635',
				'name': 'dizzy_face',
				'text': '😵'
			},
			{
				'id': '1f632',
				'name': 'astonished',
				'text': '😲'
			},
			{
				'id': '1f61f',
				'name': 'worried',
				'text': '😟'
			},
			{
				'id': '1f626',
				'name': 'frowning',
				'text': '😦'
			},
			{
				'id': '1f627',
				'name': 'anguished',
				'text': '😧'
			},
			{
				'id': '1f608',
				'name': 'smiling_imp',
				'text': '😈'
			},
			{
				'id': '1f47f',
				'name': 'imp',
				'text': '👿'
			},
			{
				'id': '1f62e',
				'name': 'open_mouth',
				'text': '😮'
			},
			{
				'id': '1f62c',
				'name': 'grimacing',
				'text': '😬'
			},
			{
				'id': '1f610',
				'name': 'neutral_face',
				'text': '😐'
			},
			{
				'id': '1f615',
				'name': 'confused',
				'text': '😕'
			},
			{
				'id': '1f62f',
				'name': 'hushed',
				'text': '😯'
			},
			{
				'id': '1f636',
				'name': 'no_mouth',
				'text': '😶'
			},
			{
				'id': '1f607',
				'name': 'innocent',
				'text': '😇'
			},
			{
				'id': '1f60f',
				'name': 'smirk',
				'text': '😏'
			},
			{
				'id': '1f611',
				'name': 'expressionless',
				'text': '😑'
			},
			{
				'id': '1f472',
				'name': 'man_with_gua_pi_mao',
				'text': '👲'
			},
			{
				'id': '1f473',
				'name': 'man_with_turban',
				'text': '👳'
			},
			{
				'id': '1f46e',
				'name': 'cop',
				'text': '👮'
			},
			{
				'id': '1f477',
				'name': 'construction_worker',
				'text': '👷'
			},
			{
				'id': '1f482',
				'name': 'guardsman',
				'text': '💂'
			},
			{
				'id': '1f476',
				'name': 'baby',
				'text': '👶'
			},
			{
				'id': '1f466',
				'name': 'boy',
				'text': '👦'
			},
			{
				'id': '1f467',
				'name': 'girl',
				'text': '👧'
			},
			{
				'id': '1f468',
				'name': 'man',
				'text': '👨'
			},
			{
				'id': '1f469',
				'name': 'woman',
				'text': '👩'
			},
			{
				'id': '1f474',
				'name': 'older_man',
				'text': '👴'
			},
			{
				'id': '1f475',
				'name': 'older_woman',
				'text': '👵'
			},
			{
				'id': '1f471',
				'name': 'person_with_blond_hair',
				'text': '👱'
			},
			{
				'id': '1f47c',
				'name': 'angel',
				'text': '👼'
			},
			{
				'id': '1f478',
				'name': 'princess',
				'text': '👸'
			},
			{
				'id': '1f63a',
				'name': 'smiley_cat',
				'text': '😺'
			},
			{
				'id': '1f638',
				'name': 'smile_cat',
				'text': '😸'
			},
			{
				'id': '1f63b',
				'name': 'heart_eyes_cat',
				'text': '😻'
			},
			{
				'id': '1f63d',
				'name': 'kissing_cat',
				'text': '😽'
			},
			{
				'id': '1f63c',
				'name': 'smirk_cat',
				'text': '😼'
			},
			{
				'id': '1f640',
				'name': 'scream_cat',
				'text': '🙀'
			},
			{
				'id': '1f63f',
				'name': 'crying_cat_face',
				'text': '😿'
			},
			{
				'id': '1f639',
				'name': 'joy_cat',
				'text': '😹'
			},
			{
				'id': '1f63e',
				'name': 'pouting_cat',
				'text': '😾'
			},
			{
				'id': '1f479',
				'name': 'japanese_ogre',
				'text': '👹'
			},
			{
				'id': '1f47a',
				'name': 'japanese_goblin',
				'text': '👺'
			},
			{
				'id': '1f648',
				'name': 'see_no_evil',
				'text': '🙈'
			},
			{
				'id': '1f649',
				'name': 'hear_no_evil',
				'text': '🙉'
			},
			{
				'id': '1f64a',
				'name': 'speak_no_evil',
				'text': '🙊'
			},
			{
				'id': '1f480',
				'name': 'skull',
				'text': '💀'
			},
			{
				'id': '1f47d',
				'name': 'alien',
				'text': '👽'
			},
			{
				'id': '1f4a9',
				'name': 'hankey',
				'text': '💩'
			},
			{
				'id': '1f525',
				'name': 'fire',
				'text': '🔥'
			},
			{
				'id': '2728',
				'name': 'sparkles',
				'text': '✨'
			},
			{
				'id': '1f31f',
				'name': 'star2',
				'text': '🌟'
			},
			{
				'id': '1f4ab',
				'name': 'dizzy',
				'text': '💫'
			},
			{
				'id': '1f4a5',
				'name': 'boom',
				'text': '💥'
			},
			{
				'id': '1f4a2',
				'name': 'anger',
				'text': '💢'
			},
			{
				'id': '1f4a6',
				'name': 'sweat_drops',
				'text': '💦'
			},
			{
				'id': '1f4a7',
				'name': 'droplet',
				'text': '💧'
			},
			{
				'id': '1f4a4',
				'name': 'zzz',
				'text': '💤'
			},
			{
				'id': '1f4a8',
				'name': 'dash',
				'text': '💨'
			},
			{
				'id': '1f442',
				'name': 'ear',
				'text': '👂'
			},
			{
				'id': '1f440',
				'name': 'eyes',
				'text': '👀'
			},
			{
				'id': '1f443',
				'name': 'nose',
				'text': '👃'
			},
			{
				'id': '1f445',
				'name': 'tongue',
				'text': '👅'
			},
			{
				'id': '1f444',
				'name': 'lips',
				'text': '👄'
			},
			{
				'id': '1f44d',
				'name': 'thumb_up',
				'text': '👍'
			},
			{
				'id': '1f44e',
				'name': 'thumb_down',
				'text': '👎'
			},
			{
				'id': '1f44c',
				'name': 'ok_hand',
				'text': '👌'
			},
			{
				'id': '1f44a',
				'name': 'facepunch',
				'text': '👊'
			},
			{
				'id': '270a',
				'name': 'fist',
				'text': '✊'
			},
			{
				'id': '270c',
				'name': 'v',
				'text': '✌️'
			},
			{
				'id': '1f44b',
				'name': 'wave',
				'text': '👋'
			},
			{
				'id': '270b',
				'name': 'hand',
				'text': '✋'
			},
			{
				'id': '1f450',
				'name': 'open_hands',
				'text': '👐'
			},
			{
				'id': '1f446',
				'name': 'point_up_2',
				'text': '👆'
			},
			{
				'id': '1f447',
				'name': 'point_down',
				'text': '👇'
			},
			{
				'id': '1f449',
				'name': 'point_right',
				'text': '👉'
			},
			{
				'id': '1f448',
				'name': 'point_left',
				'text': '👈'
			},
			{
				'id': '1f64c',
				'name': 'raised_hands',
				'text': '🙌'
			},
			{
				'id': '1f64f',
				'name': 'pray',
				'text': '🙏'
			},
			{
				'id': '261d',
				'name': 'point_up',
				'text': '☝️'
			},
			{
				'id': '1f44f',
				'name': 'clap',
				'text': '👏'
			},
			{
				'id': '1f4aa',
				'name': 'muscle',
				'text': '💪'
			},
			{
				'id': '1f6b6',
				'name': 'walking',
				'text': '🚶'
			},
			{
				'id': '1f3c3',
				'name': 'runner',
				'text': '🏃'
			},
			{
				'id': '1f483',
				'name': 'dancer',
				'text': '💃'
			},
			{
				'id': '1f46b',
				'name': 'couple',
				'text': '👫'
			},
			{
				'id': '1f46a',
				'name': 'family',
				'text': '👪'
			},
			{
				'id': '1f46c',
				'name': 'two_men_holding_hands',
				'text': '👬'
			},
			{
				'id': '1f46d',
				'name': 'two_women_holding_hands',
				'text': '👭'
			},
			{
				'id': '1f48f',
				'name': 'couplekiss',
				'text': '💏'
			},
			{
				'id': '1f491',
				'name': 'couple_with_heart',
				'text': '💑'
			},
			{
				'id': '1f46f',
				'name': 'dancers',
				'text': '👯'
			},
			{
				'id': '1f646',
				'name': 'ok_woman',
				'text': '🙆'
			},
			{
				'id': '1f645',
				'name': 'no_good',
				'text': '🙅'
			},
			{
				'id': '1f481',
				'name': 'information_desk_person',
				'text': '💁'
			},
			{
				'id': '1f64b',
				'name': 'raising_hand',
				'text': '🙋'
			},
			{
				'id': '1f486',
				'name': 'massage',
				'text': '💆'
			},
			{
				'id': '1f487',
				'name': 'haircut',
				'text': '💇'
			},
			{
				'id': '1f485',
				'name': 'nail_care',
				'text': '💅'
			},
			{
				'id': '1f470',
				'name': 'bride_with_veil',
				'text': '👰'
			},
			{
				'id': '1f64e',
				'name': 'person_with_pouting_face',
				'text': '🙎'
			},
			{
				'id': '1f64d',
				'name': 'person_frowning',
				'text': '🙍'
			},
			{
				'id': '1f647',
				'name': 'bow',
				'text': '🙇'
			},
			{
				'id': '1f3a9',
				'name': 'tophat',
				'text': '🎩'
			},
			{
				'id': '1f451',
				'name': 'crown',
				'text': '👑'
			},
			{
				'id': '1f452',
				'name': 'womans_hat',
				'text': '👒'
			},
			{
				'id': '1f45f',
				'name': 'athletic_shoe',
				'text': '👟'
			},
			{
				'id': '1f45e',
				'name': 'mans_shoe',
				'text': '👞'
			},
			{
				'id': '1f461',
				'name': 'sandal',
				'text': '👡'
			},
			{
				'id': '1f460',
				'name': 'high_heel',
				'text': '👠'
			},
			{
				'id': '1f462',
				'name': 'boot',
				'text': '👢'
			},
			{
				'id': '1f455',
				'name': 'shirt',
				'text': '👕'
			},
			{
				'id': '1f454',
				'name': 'necktie',
				'text': '👔'
			},
			{
				'id': '1f45a',
				'name': 'womans_clothes',
				'text': '👚'
			},
			{
				'id': '1f457',
				'name': 'dress',
				'text': '👗'
			},
			{
				'id': '1f3bd',
				'name': 'running_shirt_with_sash',
				'text': '🎽'
			},
			{
				'id': '1f456',
				'name': 'jeans',
				'text': '👖'
			},
			{
				'id': '1f458',
				'name': 'kimono',
				'text': '👘'
			},
			{
				'id': '1f459',
				'name': 'bikini',
				'text': '👙'
			},
			{
				'id': '1f4bc',
				'name': 'briefcase',
				'text': '💼'
			},
			{
				'id': '1f45c',
				'name': 'handbag',
				'text': '👜'
			},
			{
				'id': '1f45d',
				'name': 'pouch',
				'text': '👝'
			},
			{
				'id': '1f45b',
				'name': 'purse',
				'text': '👛'
			},
			{
				'id': '1f453',
				'name': 'eyeglasses',
				'text': '👓'
			},
			{
				'id': '1f380',
				'name': 'ribbon',
				'text': '🎀'
			},
			{
				'id': '1f302',
				'name': 'closed_umbrella',
				'text': '🌂'
			},
			{
				'id': '1f484',
				'name': 'lipstick',
				'text': '💄'
			},
			{
				'id': '1f49b',
				'name': 'yellow_heart',
				'text': '💛'
			},
			{
				'id': '1f499',
				'name': 'blue_heart',
				'text': '💙'
			},
			{
				'id': '1f49c',
				'name': 'purple_heart',
				'text': '💜'
			},
			{
				'id': '1f49a',
				'name': 'green_heart',
				'text': '💚'
			},
			{
				'id': '2764',
				'name': 'heart',
				'text': '❤️'
			},
			{
				'id': '1f494',
				'name': 'broken_heart',
				'text': '💔'
			},
			{
				'id': '1f497',
				'name': 'heartpulse',
				'text': '💗'
			},
			{
				'id': '1f493',
				'name': 'heartbeat',
				'text': '💓'
			},
			{
				'id': '1f495',
				'name': 'two_hearts',
				'text': '💕'
			},
			{
				'id': '1f496',
				'name': 'sparkling_heart',
				'text': '💖'
			},
			{
				'id': '1f49e',
				'name': 'revolving_hearts',
				'text': '💞'
			},
			{
				'id': '1f498',
				'name': 'cupid',
				'text': '💘'
			},
			{
				'id': '1f48c',
				'name': 'love_letter',
				'text': '💌'
			},
			{
				'id': '1f48b',
				'name': 'kiss',
				'text': '💋'
			},
			{
				'id': '1f48d',
				'name': 'ring',
				'text': '💍'
			},
			{
				'id': '1f48e',
				'name': 'gem',
				'text': '💎'
			},
			{
				'id': '1f464',
				'name': 'bust_in_silhouette',
				'text': '👤'
			},
			{
				'id': '1f465',
				'name': 'busts_in_silhouette',
				'text': '👥'
			},
			{
				'id': '1f4ac',
				'name': 'speech_balloon',
				'text': '💬'
			},
			{
				'id': '1f463',
				'name': 'footprints',
				'text': '👣'
			},
			{
				'id': '1f4ad',
				'name': 'thought_balloon',
				'text': '💭'
			},
			{
				'id': '1f436',
				'name': 'dog',
				'text': '🐶'
			},
			{
				'id': '1f43a',
				'name': 'wolf',
				'text': '🐺'
			},
			{
				'id': '1f431',
				'name': 'cat',
				'text': '🐱'
			},
			{
				'id': '1f42d',
				'name': 'mouse',
				'text': '🐭'
			},
			{
				'id': '1f439',
				'name': 'hamster',
				'text': '🐹'
			},
			{
				'id': '1f430',
				'name': 'rabbit',
				'text': '🐰'
			},
			{
				'id': '1f438',
				'name': 'frog',
				'text': '🐸'
			},
			{
				'id': '1f42f',
				'name': 'tiger',
				'text': '🐯'
			},
			{
				'id': '1f428',
				'name': 'koala',
				'text': '🐨'
			},
			{
				'id': '1f43b',
				'name': 'bear',
				'text': '🐻'
			},
			{
				'id': '1f437',
				'name': 'pig',
				'text': '🐷'
			},
			{
				'id': '1f43d',
				'name': 'pig_nose',
				'text': '🐽'
			},
			{
				'id': '1f42e',
				'name': 'cow',
				'text': '🐮'
			},
			{
				'id': '1f417',
				'name': 'boar',
				'text': '🐗'
			},
			{
				'id': '1f435',
				'name': 'monkey_face',
				'text': '🐵'
			},
			{
				'id': '1f412',
				'name': 'monkey',
				'text': '🐒'
			},
			{
				'id': '1f434',
				'name': 'horse',
				'text': '🐴'
			},
			{
				'id': '1f411',
				'name': 'sheep',
				'text': '🐑'
			},
			{
				'id': '1f418',
				'name': 'elephant',
				'text': '🐘'
			},
			{
				'id': '1f43c',
				'name': 'panda_face',
				'text': '🐼'
			},
			{
				'id': '1f427',
				'name': 'penguin',
				'text': '🐧'
			},
			{
				'id': '1f426',
				'name': 'bird',
				'text': '🐦'
			},
			{
				'id': '1f424',
				'name': 'baby_chick',
				'text': '🐤'
			},
			{
				'id': '1f425',
				'name': 'hatched_chick',
				'text': '🐥'
			},
			{
				'id': '1f423',
				'name': 'hatching_chick',
				'text': '🐣'
			},
			{
				'id': '1f414',
				'name': 'chicken',
				'text': '🐔'
			},
			{
				'id': '1f40d',
				'name': 'snake',
				'text': '🐍'
			},
			{
				'id': '1f422',
				'name': 'turtle',
				'text': '🐢'
			},
			{
				'id': '1f41b',
				'name': 'bug',
				'text': '🐛'
			},
			{
				'id': '1f41d',
				'name': 'bee',
				'text': '🐝'
			},
			{
				'id': '1f41c',
				'name': 'ant',
				'text': '🐜'
			},
			{
				'id': '1f41e',
				'name': 'beetle',
				'text': '🐞'
			},
			{
				'id': '1f40c',
				'name': 'snail',
				'text': '🐌'
			},
			{
				'id': '1f419',
				'name': 'octopus',
				'text': '🐙'
			},
			{
				'id': '1f41a',
				'name': 'shell',
				'text': '🐚'
			},
			{
				'id': '1f420',
				'name': 'tropical_fish',
				'text': '🐠'
			},
			{
				'id': '1f41f',
				'name': 'fish',
				'text': '🐟'
			},
			{
				'id': '1f42c',
				'name': 'dolphin',
				'text': '🐬'
			},
			{
				'id': '1f433',
				'name': 'whale',
				'text': '🐳'
			},
			{
				'id': '1f40b',
				'name': 'whale2',
				'text': '🐋'
			},
			{
				'id': '1f404',
				'name': 'cow2',
				'text': '🐄'
			},
			{
				'id': '1f40f',
				'name': 'ram',
				'text': '🐏'
			},
			{
				'id': '1f400',
				'name': 'rat',
				'text': '🐀'
			},
			{
				'id': '1f403',
				'name': 'water_buffalo',
				'text': '🐃'
			},
			{
				'id': '1f405',
				'name': 'tiger2',
				'text': '🐅'
			},
			{
				'id': '1f407',
				'name': 'rabbit2',
				'text': '🐇'
			},
			{
				'id': '1f409',
				'name': 'dragon',
				'text': '🐉'
			},
			{
				'id': '1f40e',
				'name': 'racehorse',
				'text': '🐎'
			},
			{
				'id': '1f410',
				'name': 'goat',
				'text': '🐐'
			},
			{
				'id': '1f413',
				'name': 'rooster',
				'text': '🐓'
			},
			{
				'id': '1f415',
				'name': 'dog2',
				'text': '🐕'
			},
			{
				'id': '1f416',
				'name': 'pig2',
				'text': '🐖'
			},
			{
				'id': '1f401',
				'name': 'mouse2',
				'text': '🐁'
			},
			{
				'id': '1f402',
				'name': 'ox',
				'text': '🐂'
			},
			{
				'id': '1f432',
				'name': 'dragon_face',
				'text': '🐲'
			},
			{
				'id': '1f421',
				'name': 'blowfish',
				'text': '🐡'
			},
			{
				'id': '1f40a',
				'name': 'crocodile',
				'text': '🐊'
			},
			{
				'id': '1f42b',
				'name': 'camel',
				'text': '🐫'
			},
			{
				'id': '1f42a',
				'name': 'dromedary_camel',
				'text': '🐪'
			},
			{
				'id': '1f406',
				'name': 'leopard',
				'text': '🐆'
			},
			{
				'id': '1f408',
				'name': 'cat2',
				'text': '🐈'
			},
			{
				'id': '1f429',
				'name': 'poodle',
				'text': '🐩'
			},
			{
				'id': '1f43e',
				'name': 'feet',
				'text': '🐾'
			},
			{
				'id': '1f490',
				'name': 'bouquet',
				'text': '💐'
			},
			{
				'id': '1f338',
				'name': 'cherry_blossom',
				'text': '🌸'
			},
			{
				'id': '1f337',
				'name': 'tulip',
				'text': '🌷'
			},
			{
				'id': '1f340',
				'name': 'four_leaf_clover',
				'text': '🍀'
			},
			{
				'id': '1f339',
				'name': 'rose',
				'text': '🌹'
			},
			{
				'id': '1f33b',
				'name': 'sunflower',
				'text': '🌻'
			},
			{
				'id': '1f33a',
				'name': 'hibiscus',
				'text': '🌺'
			},
			{
				'id': '1f341',
				'name': 'maple_leaf',
				'text': '🍁'
			},
			{
				'id': '1f343',
				'name': 'leaves',
				'text': '🍃'
			},
			{
				'id': '1f342',
				'name': 'fallen_leaf',
				'text': '🍂'
			},
			{
				'id': '1f33f',
				'name': 'herb',
				'text': '🌿'
			},
			{
				'id': '1f33e',
				'name': 'ear_of_rice',
				'text': '🌾'
			},
			{
				'id': '1f344',
				'name': 'mushroom',
				'text': '🍄'
			},
			{
				'id': '1f335',
				'name': 'cactus',
				'text': '🌵'
			},
			{
				'id': '1f334',
				'name': 'palm_tree',
				'text': '🌴'
			},
			{
				'id': '1f332',
				'name': 'evergreen_tree',
				'text': '🌲'
			},
			{
				'id': '1f333',
				'name': 'deciduous_tree',
				'text': '🌳'
			},
			{
				'id': '1f330',
				'name': 'chestnut',
				'text': '🌰'
			},
			{
				'id': '1f331',
				'name': 'seedling',
				'text': '🌱'
			},
			{
				'id': '1f33c',
				'name': 'blossom',
				'text': '🌼'
			},
			{
				'id': '1f310',
				'name': 'globe_with_meridians',
				'text': '🌐'
			},
			{
				'id': '1f31e',
				'name': 'sun_with_face',
				'text': '🌞'
			},
			{
				'id': '1f31d',
				'name': 'full_moon_with_face',
				'text': '🌝'
			},
			{
				'id': '1f31a',
				'name': 'new_moon_with_face',
				'text': '🌚'
			},
			{
				'id': '1f311',
				'name': 'new_moon',
				'text': '🌑'
			},
			{
				'id': '1f312',
				'name': 'waxing_crescent_moon',
				'text': '🌒'
			},
			{
				'id': '1f313',
				'name': 'first_quarter_moon',
				'text': '🌓'
			},
			{
				'id': '1f314',
				'name': 'moon',
				'text': '🌔'
			},
			{
				'id': '1f315',
				'name': 'full_moon',
				'text': '🌕'
			},
			{
				'id': '1f316',
				'name': 'waning_gibbous_moon',
				'text': '🌖'
			},
			{
				'id': '1f317',
				'name': 'last_quarter_moon',
				'text': '🌗'
			},
			{
				'id': '1f318',
				'name': 'waning_crescent_moon',
				'text': '🌘'
			},
			{
				'id': '1f31c',
				'name': 'last_quarter_moon_with_face',
				'text': '🌜'
			},
			{
				'id': '1f31b',
				'name': 'first_quarter_moon_with_face',
				'text': '🌛'
			},
			{
				'id': '1f319',
				'name': 'crescent_moon',
				'text': '🌙'
			},
			{
				'id': '1f30d',
				'name': 'earth_africa',
				'text': '🌍'
			},
			{
				'id': '1f30e',
				'name': 'earth_americas',
				'text': '🌎'
			},
			{
				'id': '1f30f',
				'name': 'earth_asia',
				'text': '🌏'
			},
			{
				'id': '1f30b',
				'name': 'volcano',
				'text': '🌋'
			},
			{
				'id': '1f30c',
				'name': 'milky_way',
				'text': '🌌'
			},
			{
				'id': '1f320',
				'name': 'stars',
				'text': '🌠'
			},
			{
				'id': '2b50',
				'name': 'star',
				'text': '⭐️'
			},
			{
				'id': '2600',
				'name': 'sunny',
				'text': '☀️'
			},
			{
				'id': '26c5',
				'name': 'partly_sunny',
				'text': '⛅️'
			},
			{
				'id': '2601',
				'name': 'cloud',
				'text': '☁️'
			},
			{
				'id': '26a1',
				'name': 'zap',
				'text': '⚡️'
			},
			{
				'id': '2614',
				'name': 'umbrella',
				'text': '☔️'
			},
			{
				'id': '2744',
				'name': 'snowflake',
				'text': '❄️'
			},
			{
				'id': '26c4',
				'name': 'snowman',
				'text': '⛄️'
			},
			{
				'id': '1f300',
				'name': 'cyclone',
				'text': '🌀'
			},
			{
				'id': '1f301',
				'name': 'foggy',
				'text': '🌁'
			},
			{
				'id': '1f308',
				'name': 'rainbow',
				'text': '🌈'
			},
			{
				'id': '1f30a',
				'name': 'ocean',
				'text': '🌊'
			},
			{
				'id': '1f38d',
				'name': 'bamboo',
				'text': '🎍'
			},
			{
				'id': '1f49d',
				'name': 'gift_heart',
				'text': '💝'
			},
			{
				'id': '1f38e',
				'name': 'dolls',
				'text': '🎎'
			},
			{
				'id': '1f392',
				'name': 'school_satchel',
				'text': '🎒'
			},
			{
				'id': '1f393',
				'name': 'mortar_board',
				'text': '🎓'
			},
			{
				'id': '1f38f',
				'name': 'flags',
				'text': '🎏'
			},
			{
				'id': '1f386',
				'name': 'fireworks',
				'text': '🎆'
			},
			{
				'id': '1f387',
				'name': 'sparkler',
				'text': '🎇'
			},
			{
				'id': '1f390',
				'name': 'wind_chime',
				'text': '🎐'
			},
			{
				'id': '1f391',
				'name': 'rice_scene',
				'text': '🎑'
			},
			{
				'id': '1f383',
				'name': 'jack_o_lantern',
				'text': '🎃'
			},
			{
				'id': '1f47b',
				'name': 'ghost',
				'text': '👻'
			},
			{
				'id': '1f385',
				'name': 'santa',
				'text': '🎅'
			},
			{
				'id': '1f384',
				'name': 'christmas_tree',
				'text': '🎄'
			},
			{
				'id': '1f381',
				'name': 'gift',
				'text': '🎁'
			},
			{
				'id': '1f38b',
				'name': 'tanabata_tree',
				'text': '🎋'
			},
			{
				'id': '1f389',
				'name': 'tada',
				'text': '🎉'
			},
			{
				'id': '1f38a',
				'name': 'confetti_ball',
				'text': '🎊'
			},
			{
				'id': '1f388',
				'name': 'balloon',
				'text': '🎈'
			},
			{
				'id': '1f38c',
				'name': 'crossed_flags',
				'text': '🎌'
			},
			{
				'id': '1f52e',
				'name': 'crystal_ball',
				'text': '🔮'
			},
			{
				'id': '1f3a5',
				'name': 'movie_camera',
				'text': '🎥'
			},
			{
				'id': '1f4f7',
				'name': 'camera',
				'text': '📷'
			},
			{
				'id': '1f4f9',
				'name': 'video_camera',
				'text': '📹'
			},
			{
				'id': '1f4fc',
				'name': 'vhs',
				'text': '📼'
			},
			{
				'id': '1f4bf',
				'name': 'cd',
				'text': '💿'
			},
			{
				'id': '1f4c0',
				'name': 'dvd',
				'text': '📀'
			},
			{
				'id': '1f4bd',
				'name': 'minidisc',
				'text': '💽'
			},
			{
				'id': '1f4be',
				'name': 'floppy_disk',
				'text': '💾'
			},
			{
				'id': '1f4bb',
				'name': 'computer',
				'text': '💻'
			},
			{
				'id': '1f4f1',
				'name': 'iphone',
				'text': '📱'
			},
			{
				'id': '260e',
				'name': 'phone',
				'text': '☎️'
			},
			{
				'id': '1f4de',
				'name': 'telephone_receiver',
				'text': '📞'
			},
			{
				'id': '1f4df',
				'name': 'pager',
				'text': '📟'
			},
			{
				'id': '1f4e0',
				'name': 'fax',
				'text': '📠'
			},
			{
				'id': '1f4e1',
				'name': 'satellite',
				'text': '📡'
			},
			{
				'id': '1f4fa',
				'name': 'tv',
				'text': '📺'
			},
			{
				'id': '1f4fb',
				'name': 'radio',
				'text': '📻'
			},
			{
				'id': '1f50a',
				'name': 'loud_sound',
				'text': '🔊'
			},
			{
				'id': '1f509',
				'name': 'sound',
				'text': '🔉'
			},
			{
				'id': '1f508',
				'name': 'speaker',
				'text': '🔈'
			},
			{
				'id': '1f507',
				'name': 'mute',
				'text': '🔇'
			},
			{
				'id': '1f514',
				'name': 'bell',
				'text': '🔔'
			},
			{
				'id': '1f515',
				'name': 'no_bell',
				'text': '🔕'
			},
			{
				'id': '1f4e3',
				'name': 'mega',
				'text': '📣'
			},
			{
				'id': '1f4e2',
				'name': 'loudspeaker',
				'text': '📢'
			},
			{
				'id': '23f3',
				'name': 'hourglass_flowing_sand',
				'text': '⏳'
			},
			{
				'id': '231b',
				'name': 'hourglass',
				'text': '⌛️'
			},
			{
				'id': '23f0',
				'name': 'alarm_clock',
				'text': '⏰'
			},
			{
				'id': '231a',
				'name': 'watch',
				'text': '⌚️'
			},
			{
				'id': '1f513',
				'name': 'unlock',
				'text': '🔓'
			},
			{
				'id': '1f512',
				'name': 'lock',
				'text': '🔒'
			},
			{
				'id': '1f50f',
				'name': 'lock_with_ink_pen',
				'text': '🔏'
			},
			{
				'id': '1f510',
				'name': 'closed_lock_with_key',
				'text': '🔐'
			},
			{
				'id': '1f511',
				'name': 'key',
				'text': '🔑'
			},
			{
				'id': '1f50e',
				'name': 'mag_right',
				'text': '🔎'
			},
			{
				'id': '1f4a1',
				'name': 'bulb',
				'text': '💡'
			},
			{
				'id': '1f526',
				'name': 'flashlight',
				'text': '🔦'
			},
			{
				'id': '1f506',
				'name': 'high_brightness',
				'text': '🔆'
			},
			{
				'id': '1f505',
				'name': 'low_brightness',
				'text': '🔅'
			},
			{
				'id': '1f50c',
				'name': 'electric_plug',
				'text': '🔌'
			},
			{
				'id': '1f50b',
				'name': 'battery',
				'text': '🔋'
			},
			{
				'id': '1f50d',
				'name': 'mag',
				'text': '🔍'
			},
			{
				'id': '1f6c0',
				'name': 'bath',
				'text': '🛀'
			},
			{
				'id': '1f6c1',
				'name': 'bathtub',
				'text': '🛁'
			},
			{
				'id': '1f6bf',
				'name': 'shower',
				'text': '🚿'
			},
			{
				'id': '1f6bd',
				'name': 'toilet',
				'text': '🚽'
			},
			{
				'id': '1f527',
				'name': 'wrench',
				'text': '🔧'
			},
			{
				'id': '1f529',
				'name': 'nut_and_bolt',
				'text': '🔩'
			},
			{
				'id': '1f528',
				'name': 'hammer',
				'text': '🔨'
			},
			{
				'id': '1f6aa',
				'name': 'door',
				'text': '🚪'
			},
			{
				'id': '1f6ac',
				'name': 'smoking',
				'text': '🚬'
			},
			{
				'id': '1f4a3',
				'name': 'bomb',
				'text': '💣'
			},
			{
				'id': '1f52b',
				'name': 'gun',
				'text': '🔫'
			},
			{
				'id': '1f52a',
				'name': 'hocho',
				'text': '🔪'
			},
			{
				'id': '1f48a',
				'name': 'pill',
				'text': '💊'
			},
			{
				'id': '1f489',
				'name': 'syringe',
				'text': '💉'
			},
			{
				'id': '1f4b0',
				'name': 'moneybag',
				'text': '💰'
			},
			{
				'id': '1f4b4',
				'name': 'yen',
				'text': '💴'
			},
			{
				'id': '1f4b5',
				'name': 'dollar',
				'text': '💵'
			},
			{
				'id': '1f4b7',
				'name': 'pound',
				'text': '💷'
			},
			{
				'id': '1f4b6',
				'name': 'euro',
				'text': '💶'
			},
			{
				'id': '1f4b3',
				'name': 'credit_card',
				'text': '💳'
			},
			{
				'id': '1f4b8',
				'name': 'money_with_wings',
				'text': '💸'
			},
			{
				'id': '1f4f2',
				'name': 'calling',
				'text': '📲'
			},
			{
				'id': '1f4e7',
				'name': 'e-mail',
				'text': '📧'
			},
			{
				'id': '1f4e5',
				'name': 'inbox_tray',
				'text': '📥'
			},
			{
				'id': '1f4e4',
				'name': 'outbox_tray',
				'text': '📤'
			},
			{
				'id': '2709',
				'name': 'email',
				'text': '✉️'
			},
			{
				'id': '1f4e9',
				'name': 'envelope_with_arrow',
				'text': '📩'
			},
			{
				'id': '1f4e8',
				'name': 'incoming_envelope',
				'text': '📨'
			},
			{
				'id': '1f4ef',
				'name': 'postal_horn',
				'text': '📯'
			},
			{
				'id': '1f4eb',
				'name': 'mailbox',
				'text': '📫'
			},
			{
				'id': '1f4ea',
				'name': 'mailbox_closed',
				'text': '📪'
			},
			{
				'id': '1f4ec',
				'name': 'mailbox_with_mail',
				'text': '📬'
			},
			{
				'id': '1f4ed',
				'name': 'mailbox_with_no_mail',
				'text': '📭'
			},
			{
				'id': '1f4ee',
				'name': 'postbox',
				'text': '📮'
			},
			{
				'id': '1f4e6',
				'name': 'package',
				'text': '📦'
			},
			{
				'id': '1f4dd',
				'name': 'memo',
				'text': '📝'
			},
			{
				'id': '1f4c4',
				'name': 'page_facing_up',
				'text': '📄'
			},
			{
				'id': '1f4c3',
				'name': 'page_with_curl',
				'text': '📃'
			},
			{
				'id': '1f4d1',
				'name': 'bookmark_tabs',
				'text': '📑'
			},
			{
				'id': '1f4ca',
				'name': 'bar_chart',
				'text': '📊'
			},
			{
				'id': '1f4c8',
				'name': 'chart_with_upwards_trend',
				'text': '📈'
			},
			{
				'id': '1f4c9',
				'name': 'chart_with_downwards_trend',
				'text': '📉'
			},
			{
				'id': '1f4dc',
				'name': 'scroll',
				'text': '📜'
			},
			{
				'id': '1f4cb',
				'name': 'clipboard',
				'text': '📋'
			},
			{
				'id': '1f4c5',
				'name': 'date',
				'text': '📅'
			},
			{
				'id': '1f4c6',
				'name': 'calendar',
				'text': '📆'
			},
			{
				'id': '1f4c7',
				'name': 'card_index',
				'text': '📇'
			},
			{
				'id': '1f4c1',
				'name': 'file_folder',
				'text': '📁'
			},
			{
				'id': '1f4c2',
				'name': 'open_file_folder',
				'text': '📂'
			},
			{
				'id': '2702',
				'name': 'scissors',
				'text': '✂️'
			},
			{
				'id': '1f4cc',
				'name': 'pushpin',
				'text': '📌'
			},
			{
				'id': '1f4ce',
				'name': 'paperclip',
				'text': '📎'
			},
			{
				'id': '2712',
				'name': 'black_nib',
				'text': '✒️'
			},
			{
				'id': '270f',
				'name': 'pencil2',
				'text': '✏️'
			},
			{
				'id': '1f4cf',
				'name': 'straight_ruler',
				'text': '📏'
			},
			{
				'id': '1f4d0',
				'name': 'triangular_ruler',
				'text': '📐'
			},
			{
				'id': '1f4d5',
				'name': 'closed_book',
				'text': '📕'
			},
			{
				'id': '1f4d7',
				'name': 'green_book',
				'text': '📗'
			},
			{
				'id': '1f4d8',
				'name': 'blue_book',
				'text': '📘'
			},
			{
				'id': '1f4d9',
				'name': 'orange_book',
				'text': '📙'
			},
			{
				'id': '1f4d3',
				'name': 'notebook',
				'text': '📓'
			},
			{
				'id': '1f4d4',
				'name': 'notebook_with_decorative_cover',
				'text': '📔'
			},
			{
				'id': '1f4d2',
				'name': 'ledger',
				'text': '📒'
			},
			{
				'id': '1f4da',
				'name': 'books',
				'text': '📚'
			},
			{
				'id': '1f4d6',
				'name': 'book',
				'text': '📖'
			},
			{
				'id': '1f516',
				'name': 'bookmark',
				'text': '🔖'
			},
			{
				'id': '1f4db',
				'name': 'name_badge',
				'text': '📛'
			},
			{
				'id': '1f52c',
				'name': 'microscope',
				'text': '🔬'
			},
			{
				'id': '1f52d',
				'name': 'telescope',
				'text': '🔭'
			},
			{
				'id': '1f4f0',
				'name': 'newspaper',
				'text': '📰'
			},
			{
				'id': '1f3a8',
				'name': 'art',
				'text': '🎨'
			},
			{
				'id': '1f3ac',
				'name': 'clapper',
				'text': '🎬'
			},
			{
				'id': '1f3a4',
				'name': 'microphone',
				'text': '🎤'
			},
			{
				'id': '1f3a7',
				'name': 'headphones',
				'text': '🎧'
			},
			{
				'id': '1f3bc',
				'name': 'musical_score',
				'text': '🎼'
			},
			{
				'id': '1f3b5',
				'name': 'musical_note',
				'text': '🎵'
			},
			{
				'id': '1f3b6',
				'name': 'notes',
				'text': '🎶'
			},
			{
				'id': '1f3b9',
				'name': 'musical_keyboard',
				'text': '🎹'
			},
			{
				'id': '1f3bb',
				'name': 'violin',
				'text': '🎻'
			},
			{
				'id': '1f3ba',
				'name': 'trumpet',
				'text': '🎺'
			},
			{
				'id': '1f3b7',
				'name': 'saxophone',
				'text': '🎷'
			},
			{
				'id': '1f3b8',
				'name': 'guitar',
				'text': '🎸'
			},
			{
				'id': '1f47e',
				'name': 'space_invader',
				'text': '👾'
			},
			{
				'id': '1f3ae',
				'name': 'video_game',
				'text': '🎮'
			},
			{
				'id': '1f0cf',
				'name': 'black_joker',
				'text': '🃏'
			},
			{
				'id': '1f3b4',
				'name': 'flower_playing_cards',
				'text': '🎴'
			},
			{
				'id': '1f004',
				'name': 'mahjong',
				'text': '🀄️'
			},
			{
				'id': '1f3b2',
				'name': 'game_die',
				'text': '🎲'
			},
			{
				'id': '1f3af',
				'name': 'dart',
				'text': '🎯'
			},
			{
				'id': '1f3c8',
				'name': 'football',
				'text': '🏈'
			},
			{
				'id': '1f3c0',
				'name': 'basketball',
				'text': '🏀'
			},
			{
				'id': '26bd',
				'name': 'soccer',
				'text': '⚽️'
			},
			{
				'id': '26be',
				'name': 'baseball',
				'text': '⚾️'
			},
			{
				'id': '1f3be',
				'name': 'tennis',
				'text': '🎾'
			},
			{
				'id': '1f3b1',
				'name': '8ball',
				'text': '🎱'
			},
			{
				'id': '1f3c9',
				'name': 'rugby_football',
				'text': '🏉'
			},
			{
				'id': '1f3b3',
				'name': 'bowling',
				'text': '🎳'
			},
			{
				'id': '26f3',
				'name': 'golf',
				'text': '⛳️'
			},
			{
				'id': '1f6b5',
				'name': 'mountain_bicyclist',
				'text': '🚵'
			},
			{
				'id': '1f6b4',
				'name': 'bicyclist',
				'text': '🚴'
			},
			{
				'id': '1f3c1',
				'name': 'checkered_flag',
				'text': '🏁'
			},
			{
				'id': '1f3c7',
				'name': 'horse_racing',
				'text': '🏇'
			},
			{
				'id': '1f3c6',
				'name': 'trophy',
				'text': '🏆'
			},
			{
				'id': '1f3bf',
				'name': 'ski',
				'text': '🎿'
			},
			{
				'id': '1f3c2',
				'name': 'snowboarder',
				'text': '🏂'
			},
			{
				'id': '1f3ca',
				'name': 'swimmer',
				'text': '🏊'
			},
			{
				'id': '1f3c4',
				'name': 'surfer',
				'text': '🏄'
			},
			{
				'id': '1f3a3',
				'name': 'fishing_pole_and_fish',
				'text': '🎣'
			},
			{
				'id': '2615',
				'name': 'coffee',
				'text': '☕️'
			},
			{
				'id': '1f375',
				'name': 'tea',
				'text': '🍵'
			},
			{
				'id': '1f376',
				'name': 'sake',
				'text': '🍶'
			},
			{
				'id': '1f37c',
				'name': 'baby_bottle',
				'text': '🍼'
			},
			{
				'id': '1f37a',
				'name': 'beer',
				'text': '🍺'
			},
			{
				'id': '1f37b',
				'name': 'beers',
				'text': '🍻'
			},
			{
				'id': '1f378',
				'name': 'cocktail',
				'text': '🍸'
			},
			{
				'id': '1f379',
				'name': 'tropical_drink',
				'text': '🍹'
			},
			{
				'id': '1f377',
				'name': 'wine_glass',
				'text': '🍷'
			},
			{
				'id': '1f374',
				'name': 'fork_and_knife',
				'text': '🍴'
			},
			{
				'id': '1f355',
				'name': 'pizza',
				'text': '🍕'
			},
			{
				'id': '1f354',
				'name': 'hamburger',
				'text': '🍔'
			},
			{
				'id': '1f35f',
				'name': 'fries',
				'text': '🍟'
			},
			{
				'id': '1f357',
				'name': 'poultry_leg',
				'text': '🍗'
			},
			{
				'id': '1f356',
				'name': 'meat_on_bone',
				'text': '🍖'
			},
			{
				'id': '1f35d',
				'name': 'spaghetti',
				'text': '🍝'
			},
			{
				'id': '1f35b',
				'name': 'curry',
				'text': '🍛'
			},
			{
				'id': '1f364',
				'name': 'fried_shrimp',
				'text': '🍤'
			},
			{
				'id': '1f371',
				'name': 'bento',
				'text': '🍱'
			},
			{
				'id': '1f363',
				'name': 'sushi',
				'text': '🍣'
			},
			{
				'id': '1f365',
				'name': 'fish_cake',
				'text': '🍥'
			},
			{
				'id': '1f359',
				'name': 'rice_ball',
				'text': '🍙'
			},
			{
				'id': '1f358',
				'name': 'rice_cracker',
				'text': '🍘'
			},
			{
				'id': '1f35a',
				'name': 'rice',
				'text': '🍚'
			},
			{
				'id': '1f35c',
				'name': 'ramen',
				'text': '🍜'
			},
			{
				'id': '1f372',
				'name': 'stew',
				'text': '🍲'
			},
			{
				'id': '1f362',
				'name': 'oden',
				'text': '🍢'
			},
			{
				'id': '1f361',
				'name': 'dango',
				'text': '🍡'
			},
			{
				'id': '1f373',
				'name': 'egg',
				'text': '🍳'
			},
			{
				'id': '1f35e',
				'name': 'bread',
				'text': '🍞'
			},
			{
				'id': '1f369',
				'name': 'doughnut',
				'text': '🍩'
			},
			{
				'id': '1f36e',
				'name': 'custard',
				'text': '🍮'
			},
			{
				'id': '1f366',
				'name': 'icecream',
				'text': '🍦'
			},
			{
				'id': '1f368',
				'name': 'ice_cream',
				'text': '🍨'
			},
			{
				'id': '1f367',
				'name': 'shaved_ice',
				'text': '🍧'
			},
			{
				'id': '1f382',
				'name': 'birthday',
				'text': '🎂'
			},
			{
				'id': '1f370',
				'name': 'cake',
				'text': '🍰'
			},
			{
				'id': '1f36a',
				'name': 'cookie',
				'text': '🍪'
			},
			{
				'id': '1f36b',
				'name': 'chocolate_bar',
				'text': '🍫'
			},
			{
				'id': '1f36c',
				'name': 'candy',
				'text': '🍬'
			},
			{
				'id': '1f36d',
				'name': 'lollipop',
				'text': '🍭'
			},
			{
				'id': '1f36f',
				'name': 'honey_pot',
				'text': '🍯'
			},
			{
				'id': '1f34e',
				'name': 'apple',
				'text': '🍎'
			},
			{
				'id': '1f34f',
				'name': 'green_apple',
				'text': '🍏'
			},
			{
				'id': '1f34a',
				'name': 'tangerine',
				'text': '🍊'
			},
			{
				'id': '1f34b',
				'name': 'lemon',
				'text': '🍋'
			},
			{
				'id': '1f352',
				'name': 'cherries',
				'text': '🍒'
			},
			{
				'id': '1f347',
				'name': 'grapes',
				'text': '🍇'
			},
			{
				'id': '1f349',
				'name': 'watermelon',
				'text': '🍉'
			},
			{
				'id': '1f353',
				'name': 'strawberry',
				'text': '🍓'
			},
			{
				'id': '1f351',
				'name': 'peach',
				'text': '🍑'
			},
			{
				'id': '1f348',
				'name': 'melon',
				'text': '🍈'
			},
			{
				'id': '1f34c',
				'name': 'banana',
				'text': '🍌'
			},
			{
				'id': '1f350',
				'name': 'pear',
				'text': '🍐'
			},
			{
				'id': '1f34d',
				'name': 'pineapple',
				'text': '🍍'
			},
			{
				'id': '1f360',
				'name': 'sweet_potato',
				'text': '🍠'
			},
			{
				'id': '1f346',
				'name': 'eggplant',
				'text': '🍆'
			},
			{
				'id': '1f345',
				'name': 'tomato',
				'text': '🍅'
			},
			{
				'id': '1f33d',
				'name': 'corn',
				'text': '🌽'
			},
			{
				'id': '1f3e0',
				'name': 'house',
				'text': '🏠'
			},
			{
				'id': '1f3e1',
				'name': 'house_with_garden',
				'text': '🏡'
			},
			{
				'id': '1f3eb',
				'name': 'school',
				'text': '🏫'
			},
			{
				'id': '1f3e2',
				'name': 'office',
				'text': '🏢'
			},
			{
				'id': '1f3e3',
				'name': 'post_office',
				'text': '🏣'
			},
			{
				'id': '1f3e5',
				'name': 'hospital',
				'text': '🏥'
			},
			{
				'id': '1f3e6',
				'name': 'bank',
				'text': '🏦'
			},
			{
				'id': '1f3ea',
				'name': 'convenience_store',
				'text': '🏪'
			},
			{
				'id': '1f3e9',
				'name': 'love_hotel',
				'text': '🏩'
			},
			{
				'id': '1f3e8',
				'name': 'hotel',
				'text': '🏨'
			},
			{
				'id': '1f492',
				'name': 'wedding',
				'text': '💒'
			},
			{
				'id': '26ea',
				'name': 'church',
				'text': '⛪️'
			},
			{
				'id': '1f3ec',
				'name': 'department_store',
				'text': '🏬'
			},
			{
				'id': '1f3e4',
				'name': 'european_post_office',
				'text': '🏤'
			},
			{
				'id': '1f307',
				'name': 'city_sunrise',
				'text': '🌇'
			},
			{
				'id': '1f306',
				'name': 'city_sunset',
				'text': '🌆'
			},
			{
				'id': '1f3ef',
				'name': 'japanese_castle',
				'text': '🏯'
			},
			{
				'id': '1f3f0',
				'name': 'european_castle',
				'text': '🏰'
			},
			{
				'id': '26fa',
				'name': 'tent',
				'text': '⛺️'
			},
			{
				'id': '1f3ed',
				'name': 'factory',
				'text': '🏭'
			},
			{
				'id': '1f5fc',
				'name': 'tokyo_tower',
				'text': '🗼'
			},
			{
				'id': '1f5fe',
				'name': 'japan',
				'text': '🗾'
			},
			{
				'id': '1f5fb',
				'name': 'mount_fuji',
				'text': '🗻'
			},
			{
				'id': '1f304',
				'name': 'sunrise_over_mountains',
				'text': '🌄'
			},
			{
				'id': '1f305',
				'name': 'sunrise',
				'text': '🌅'
			},
			{
				'id': '1f303',
				'name': 'night_with_stars',
				'text': '🌃'
			},
			{
				'id': '1f5fd',
				'name': 'statue_of_liberty',
				'text': '🗽'
			},
			{
				'id': '1f309',
				'name': 'bridge_at_night',
				'text': '🌉'
			},
			{
				'id': '1f3a0',
				'name': 'carousel_horse',
				'text': '🎠'
			},
			{
				'id': '1f3a1',
				'name': 'ferris_wheel',
				'text': '🎡'
			},
			{
				'id': '26f2',
				'name': 'fountain',
				'text': '⛲️'
			},
			{
				'id': '1f3a2',
				'name': 'roller_coaster',
				'text': '🎢'
			},
			{
				'id': '1f6a2',
				'name': 'ship',
				'text': '🚢'
			},
			{
				'id': '26f5',
				'name': 'boat',
				'text': '⛵️'
			},
			{
				'id': '1f6a4',
				'name': 'speedboat',
				'text': '🚤'
			},
			{
				'id': '1f6a3',
				'name': 'rowboat',
				'text': '🚣'
			},
			{
				'id': '2693',
				'name': 'anchor',
				'text': '⚓️'
			},
			{
				'id': '1f680',
				'name': 'rocket',
				'text': '🚀'
			},
			{
				'id': '2708',
				'name': 'airplane',
				'text': '✈️'
			},
			{
				'id': '1f4ba',
				'name': 'seat',
				'text': '💺'
			},
			{
				'id': '1f681',
				'name': 'helicopter',
				'text': '🚁'
			},
			{
				'id': '1f682',
				'name': 'steam_locomotive',
				'text': '🚂'
			},
			{
				'id': '1f68a',
				'name': 'tram',
				'text': '🚊'
			},
			{
				'id': '1f689',
				'name': 'station',
				'text': '🚉'
			},
			{
				'id': '1f69e',
				'name': 'mountain_railway',
				'text': '🚞'
			},
			{
				'id': '1f686',
				'name': 'train2',
				'text': '🚆'
			},
			{
				'id': '1f684',
				'name': 'bullettrain_side',
				'text': '🚄'
			},
			{
				'id': '1f685',
				'name': 'bullettrain_front',
				'text': '🚅'
			},
			{
				'id': '1f688',
				'name': 'light_rail',
				'text': '🚈'
			},
			{
				'id': '1f687',
				'name': 'metro',
				'text': '🚇'
			},
			{
				'id': '1f69d',
				'name': 'monorail',
				'text': '🚝'
			},
			{
				'id': '1f683',
				'name': 'railway_car',
				'text': '🚃'
			},
			{
				'id': '1f68b',
				'name': 'train',
				'text': '🚋'
			},
			{
				'id': '1f68e',
				'name': 'trolleybus',
				'text': '🚎'
			},
			{
				'id': '1f68c',
				'name': 'bus',
				'text': '🚌'
			},
			{
				'id': '1f68d',
				'name': 'oncoming_bus',
				'text': '🚍'
			},
			{
				'id': '1f699',
				'name': 'blue_car',
				'text': '🚙'
			},
			{
				'id': '1f698',
				'name': 'oncoming_automobile',
				'text': '🚘'
			},
			{
				'id': '1f697',
				'name': 'car',
				'text': '🚗'
			},
			{
				'id': '1f695',
				'name': 'taxi',
				'text': '🚕'
			},
			{
				'id': '1f696',
				'name': 'oncoming_taxi',
				'text': '🚖'
			},
			{
				'id': '1f69b',
				'name': 'articulated_lorry',
				'text': '🚛'
			},
			{
				'id': '1f69a',
				'name': 'truck',
				'text': '🚚'
			},
			{
				'id': '1f6a8',
				'name': 'rotating_light',
				'text': '🚨'
			},
			{
				'id': '1f693',
				'name': 'police_car',
				'text': '🚓'
			},
			{
				'id': '1f694',
				'name': 'oncoming_police_car',
				'text': '🚔'
			},
			{
				'id': '1f692',
				'name': 'fire_engine',
				'text': '🚒'
			},
			{
				'id': '1f691',
				'name': 'ambulance',
				'text': '🚑'
			},
			{
				'id': '1f690',
				'name': 'minibus',
				'text': '🚐'
			},
			{
				'id': '1f6b2',
				'name': 'bike',
				'text': '🚲'
			},
			{
				'id': '1f6a1',
				'name': 'aerial_tramway',
				'text': '🚡'
			},
			{
				'id': '1f69f',
				'name': 'suspension_railway',
				'text': '🚟'
			},
			{
				'id': '1f6a0',
				'name': 'mountain_cableway',
				'text': '🚠'
			},
			{
				'id': '1f69c',
				'name': 'tractor',
				'text': '🚜'
			},
			{
				'id': '1f488',
				'name': 'barber',
				'text': '💈'
			},
			{
				'id': '1f68f',
				'name': 'busstop',
				'text': '🚏'
			},
			{
				'id': '1f3ab',
				'name': 'ticket',
				'text': '🎫'
			},
			{
				'id': '1f6a6',
				'name': 'vertical_traffic_light',
				'text': '🚦'
			},
			{
				'id': '1f6a5',
				'name': 'traffic_light',
				'text': '🚥'
			},
			{
				'id': '26a0',
				'name': 'warning',
				'text': '⚠️'
			},
			{
				'id': '1f6a7',
				'name': 'construction',
				'text': '🚧'
			},
			{
				'id': '1f530',
				'name': 'beginner',
				'text': '🔰'
			},
			{
				'id': '26fd',
				'name': 'fuelpump',
				'text': '⛽️'
			},
			{
				'id': '1f3ee',
				'name': 'izakaya_lantern',
				'text': '🏮'
			},
			{
				'id': '1f3b0',
				'name': 'slot_machine',
				'text': '🎰'
			},
			{
				'id': '2668',
				'name': 'hotsprings',
				'text': '♨️'
			},
			{
				'id': '1f5ff',
				'name': 'moyai',
				'text': '🗿'
			},
			{
				'id': '1f3aa',
				'name': 'circus_tent',
				'text': '🎪'
			},
			{
				'id': '1f3ad',
				'name': 'performing_arts',
				'text': '🎭'
			},
			{
				'id': '1f4cd',
				'name': 'round_pushpin',
				'text': '📍'
			},
			{
				'id': '1f6a9',
				'name': 'triangular_flag_on_post',
				'text': '🚩'
			},
			{
				'id': '1f1ef-1f1f5',
				'name': 'jp',
				'text': '🇯🇵'
			},
			{
				'id': '1f1f0-1f1f7',
				'name': 'kr',
				'text': '🇰🇷'
			},
			{
				'id': '1f1e9-1f1ea',
				'name': 'de',
				'text': '🇩🇪'
			},
			{
				'id': '1f1e8-1f1f3',
				'name': 'cn',
				'text': '🇨🇳'
			},
			{
				'id': '1f1fa-1f1f8',
				'name': 'us',
				'text': '🇺🇸'
			},
			{
				'id': '1f1eb-1f1f7',
				'name': 'fr',
				'text': '🇫🇷'
			},
			{
				'id': '1f1ea-1f1f8',
				'name': 'es',
				'text': '🇪🇸'
			},
			{
				'id': '1f1ee-1f1f9',
				'name': 'it',
				'text': '🇮🇹'
			},
			{
				'id': '1f1f7-1f1fa',
				'name': 'ru',
				'text': '🇷🇺'
			},
			{
				'id': '1f1ec-1f1e7',
				'name': 'gb',
				'text': '🇬🇧'
			}
		] );

		editor.config.define( 'emoji', [
			{
				'id': '1f604',
				'name': 'smile',
				'text': '😄'
			},
			{
				'id': '1f603',
				'name': 'smiley',
				'text': '😃'
			},
			{
				'id': '1f600',
				'name': 'grinning',
				'text': '😀'
			},
			{
				'id': '1f60a',
				'name': 'blush',
				'text': '😊'
			},

			/**
			 {
                'id': '263a',
                'name': 'relaxed',
                'text': '☺'
            },
            **/
			{
				'id': '1f609',
				'name': 'wink',
				'text': '😉'
			},
			{
				'id': '1f60d',
				'name': 'heart_eyes',
				'text': '😍'
			},
			{
				'id': '1f618',
				'name': 'kissing_heart',
				'text': '😘'
			},
			{
				'id': '1f61a',
				'name': 'kissing_closed_eyes',
				'text': '😚'
			},
			{
				'id': '1f617',
				'name': 'kissing',
				'text': '😗'
			},
			{
				'id': '1f619',
				'name': 'kissing_smiling_eyes',
				'text': '😙'
			},
			{
				'id': '1f61c',
				'name': 'stuck_out_tongue_winking_eye',
				'text': '😜'
			},
			{
				'id': '1f61d',
				'name': 'stuck_out_tongue_closed_eyes',
				'text': '😝'
			},
			{
				'id': '1f61b',
				'name': 'stuck_out_tongue',
				'text': '😛'
			},
			{
				'id': '1f633',
				'name': 'flushed',
				'text': '😳'
			},
			{
				'id': '1f601',
				'name': 'grin',
				'text': '😁'
			},
			{
				'id': '1f614',
				'name': 'pensive',
				'text': '😔'
			},
			{
				'id': '1f60c',
				'name': 'relieved',
				'text': '😌'
			},
			{
				'id': '1f612',
				'name': 'unamused',
				'text': '😒'
			},
			{
				'id': '1f61e',
				'name': 'disappointed',
				'text': '😞'
			},
			{
				'id': '1f623',
				'name': 'persevere',
				'text': '😣'
			},
			{
				'id': '1f622',
				'name': 'cry',
				'text': '😢'
			},
			{
				'id': '1f602',
				'name': 'joy',
				'text': '😂'
			},
			{
				'id': '1f62d',
				'name': 'sob',
				'text': '😭'
			},
			{
				'id': '1f62a',
				'name': 'sleepy',
				'text': '😪'
			},
			{
				'id': '1f625',
				'name': 'disappointed_relieved',
				'text': '😥'
			},
			{
				'id': '1f630',
				'name': 'cold_sweat',
				'text': '😰'
			},
			{
				'id': '1f605',
				'name': 'sweat_smile',
				'text': '😅'
			},
			{
				'id': '1f613',
				'name': 'sweat',
				'text': '😓'
			},
			{
				'id': '1f629',
				'name': 'weary',
				'text': '😩'
			},
			{
				'id': '1f62b',
				'name': 'tired_face',
				'text': '😫'
			},
			{
				'id': '1f628',
				'name': 'fearful',
				'text': '😨'
			},
			{
				'id': '1f631',
				'name': 'scream',
				'text': '😱'
			},
			{
				'id': '1f620',
				'name': 'angry',
				'text': '😠'
			},
			{
				'id': '1f621',
				'name': 'rage',
				'text': '😡'
			},
			{
				'id': '1f624',
				'name': 'triumph',
				'text': '😤'
			},
			{
				'id': '1f616',
				'name': 'confounded',
				'text': '😖'
			},
			{
				'id': '1f606',
				'name': 'satisfied',
				'text': '😆'
			},
			{
				'id': '1f60b',
				'name': 'yum',
				'text': '😋'
			},
			{
				'id': '1f637',
				'name': 'mask',
				'text': '😷'
			},
			{
				'id': '1f60e',
				'name': 'sunglasses',
				'text': '😎'
			},
			{
				'id': '1f634',
				'name': 'sleeping',
				'text': '😴'
			},
			{
				'id': '1f635',
				'name': 'dizzy_face',
				'text': '😵'
			},
			{
				'id': '1f632',
				'name': 'astonished',
				'text': '😲'
			},
			{
				'id': '1f61f',
				'name': 'worried',
				'text': '😟'
			},
			{
				'id': '1f626',
				'name': 'frowning',
				'text': '😦'
			},
			{
				'id': '1f627',
				'name': 'anguished',
				'text': '😧'
			},
			{
				'id': '1f608',
				'name': 'smiling_imp',
				'text': '😈'
			},
			{
				'id': '1f47f',
				'name': 'imp',
				'text': '👿'
			},
			{
				'id': '1f62e',
				'name': 'open_mouth',
				'text': '😮'
			},
			{
				'id': '1f62c',
				'name': 'grimacing',
				'text': '😬'
			},
			{
				'id': '1f610',
				'name': 'neutral_face',
				'text': '😐'
			},
			{
				'id': '1f615',
				'name': 'confused',
				'text': '😕'
			},
			{
				'id': '1f62f',
				'name': 'hushed',
				'text': '😯'
			},
			{
				'id': '1f636',
				'name': 'no_mouth',
				'text': '😶'
			},
			{
				'id': '1f607',
				'name': 'innocent',
				'text': '😇'
			},
			{
				'id': '1f60f',
				'name': 'smirk',
				'text': '😏'
			},
			{
				'id': '1f611',
				'name': 'expressionless',
				'text': '😑'
			}
		] );

		/**
		 * The contextual balloon plugin instance.
		 *
		 * @private
		 * @member {module:ui/panel/balloon/contextualballoon~ContextualBalloon}
		 */
		this._balloon = editor.plugins.get( ContextualBalloon );

		/**
		 * The form view displayed inside the balloon.
		 *
		 * @member {module:emoji/ui/emojilistview~EmojiListView}
		 */
		this.formView = this._createForm();

		editor.ui.componentFactory.add( 'emoji', locale => {
			const button = new ButtonView( locale );

			button.isEnabled = true;
			button.label = editor.t( 'Emoji' );
			button.icon = emojiIcon;
			button.tooltip = true;
			// Ugly hack for https://github.com/ckeditor/ckeditor5-ui/issues/350
			/* eslint-env browser */
			setTimeout( function() {
				button.iconView.set( 'viewBox', '0 0 128 128' );
			}, 0 );

			// Show the panel on button click.
			this.listenTo( button, 'execute', () => this._showPanel( true ) );

			return button;
		} );

		this._attachActions();
	}

	/**
	 * Creates the {@link module:emoji/ui/emojilistview~EmojiListView} instance.
	 *
	 * @private
	 * @returns {module:emoji/ui/emojilistview~EmojiListView} The emoji list view instance.
	 */
	_createForm() {
		const editor = this.editor;
		const emojiView = new EmojiListView( editor );

		editor.config.get( 'emoji' ).forEach( emoji => {
			this.listenTo( emojiView, 'emoji:' + emoji.name, () => {
				editor.model.change( writer => {
					writer.insertText( emoji.text, editor.model.document.selection.getFirstPosition() );
					this._hidePanel();
				} );
			} );
		} );

		// Close the panel on esc key press when the form has focus.
		emojiView.keystrokes.set( 'Esc', ( data, cancel ) => {
			this._hidePanel( true );
			cancel();
		} );

		return emojiView;
	}

	/**
	 * Returns positioning options for the {@link #_balloon}. They control the way the balloon is attached
	 * to the target element or selection.
	 *
	 * If the selection is collapsed and inside a link element, the panel will be attached to the
	 * entire link element. Otherwise, it will be attached to the selection.
	 *
	 * @private
	 * @returns {module:utils/dom/position~Options}
	 */
	_getBalloonPositionData() {
		const view = this.editor.editing.view;
		const viewDocument = view.document;
		const target =
			view.domConverter.viewRangeToDom( viewDocument.selection.getFirstRange() );

		return { target };
	}

	/**
	 * Adds the {@link #formView} to the {@link #_balloon}.
	 */
	_showPanel( ) {
		this._balloon.add( {
			view: this.formView,
			position: this._getBalloonPositionData()
		} );
	}

	/**
	 * Attaches actions that control whether the balloon panel containing the
	 * {@link #formView} is visible or not.
	 *
	 * @private
	 */
	_attachActions() {
		// Focus the form if the balloon is visible and the Tab key has been pressed.
		this.editor.keystrokes.set( 'Tab', ( data, cancel ) => {
			if ( this._balloon.visibleView === this.formView && !this.formView.focusTracker.isFocused ) {
				this.formView.focus();
				cancel();
			}
		}, {
			// Use the high priority because the emoji UI navigation is more important
			// than other feature's actions, e.g. list indentation.
			// https://github.com/ckeditor/ckeditor5-link/issues/146
			priority: 'high'
		} );

		// Close the panel on the Esc key press when the editable has focus and the balloon is visible.
		this.editor.keystrokes.set( 'Esc', ( data, cancel ) => {
			if ( this._balloon.visibleView === this.formView ) {
				this._hidePanel();
				cancel();
			}
		} );

		// Close on click outside of balloon panel element.
		clickOutsideHandler( {
			emitter: this.formView,
			activator: () => this._balloon.hasView( this.formView ),
			contextElements: [ this._balloon.view.element ],
			callback: () => this._hidePanel()
		} );
	}

	/**
	 * Removes the {@link #formView} from the {@link #_balloon}.
	 *
	 * See {@link #_showPanel}.
	 *
	 * @protected
	 * @param {Boolean} [focusEditable=false] When `true`, editable focus will be restored on panel hide.
	 */
	_hidePanel( focusEditable ) {
		this.stopListening( this.editor.editing.view, 'render' );

		if ( !this._balloon.hasView( this.formView ) ) {
			return;
		}

		if ( focusEditable ) {
			this.editor.editing.view.focus();
		}

		this.stopListening( this.editor.editing.view, 'render' );
		this._balloon.remove( this.formView );
	}
}
